import { Component, OnInit } from '@angular/core';
import {Post} from '../../../models/post';
import {PostService} from '../../../services/post.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  public posts: Post[];
  constructor(private postServices: PostService) { }
  ngOnInit() {
    this.syncPosts();
  }
  public syncPosts() {
    this.postServices.getList('top', '3').subscribe( a => {
      this.posts = a;
    });
  }
}
