import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalConfigService} from './global-config.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(private httpClient: HttpClient,
              private globalConfig: GlobalConfigService) { }
  public SaveImageFile(postedFile: File): Observable<any> {
    const url = `${this.globalConfig.igcoopUrl}/api/mediaEdge/save/file`;
    const frmData = new FormData();
    frmData.append('file', postedFile);
    return this.httpClient.post<any>(url, frmData);
  }
}
