import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.css']
})
export class TopSectionComponent implements OnInit {
  @Input('title')
  public title: string;
  @Input('abstract')
  public abstract: string;
  @Input('backImageUrl')
  public backImageUrl: string;
  @Input('content')
  public content: string;
  constructor() { }

  ngOnInit() {
  }

}
