import { Injectable } from '@angular/core';

@Injectable()
export class GlobalConfigService {
  public customerCode = '2948b09a-53f2-4dd4-bea6-b8b14d901d49';
  public domainID = '1';
  public  adminPanelUrl = 'http://admin.igcoop.ir';
  public  apiUrl = 'http://api.igcoop.ir';
  public  igcoopUrl = 'http://94.182.191.148:8070';
}
