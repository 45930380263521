import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  saveWaiting = false;
  alertMessage = '';
  isAlertShow: boolean;
  formGroup = this.formBuilder.group({
    userName: ['', Validators.compose([Validators.required])],
    password: ['', Validators.compose([Validators.required])],
  });
  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,) { }

  ngOnInit() {
  }
  submit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.saveWaiting = true;
      const temp = this.formGroup.getRawValue() as any;
      this.authService.login(temp.userName, temp.password).subscribe(n => {
        if (n) {
          this.saveWaiting = false;
          // this.router.navigate(['/household/list']);
          window.location.href = '/household/list';
        } else  {
          this.saveWaiting = false;
          this.showAlert('danger', 'نام کاربری یا رمز عبور اشتباه است.');
          setTimeout(() => {
            this.isAlertShow = false;
          }, 5000);
        }
      }, () => {
        this.saveWaiting = false;
        this.showAlert('danger', 'خطا در ورود');
        setTimeout(() => {
          // this.isAlertShow = false;
        }, 5000);
      });
    }
  }
  showAlert(type: string, message: string) {
    // this.alert = new Alert();
    // this.alert.type = type;
    this.alertMessage = message;
    this.isAlertShow = true;
    setTimeout(() => {
      this.isAlertShow = false;
    }, 5000);
  }
  get t() { return this.formGroup.controls; }
}
