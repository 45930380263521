import {Component, Input, OnInit} from '@angular/core';
import {PageContentService} from '../../../../services/page-content.service';
import {PageContent} from '../../../../models/page-content';

@Component({
  selector: 'app-feature-box2',
  templateUrl: './feature-box2.component.html',
  styleUrls: ['./feature-box2.component.css']
})
export class FeatureBox2Component implements OnInit {
  @Input() id: number;
  // @Input() iconName: string;
  public pageContent: PageContent;
  constructor(private pageContentsServices: PageContentService) { }
  ngOnInit() {
    this.syncPageContent();
  }
  public syncPageContent() {
    this.pageContentsServices.getSingle( '', this.id.toString()).subscribe( a => {
      this.pageContent = a;
    });
  }

}
