import { Injectable } from '@angular/core';
import {GlobalConfigService} from './global-config.service';
import {ContentType} from '../models/enums/content-type.enum';

enum FolderType {
  imagesByFolder,
  images
}
@Injectable({
  providedIn: 'root'
})
export class ContentService {
   constructor(private globalConfig: GlobalConfigService) { }
   public getPicturePathByFolder(cnt: ContentType, w: number, h: number, id: number , regMoment: string, mainImgeID: number): string {
    let str = `${this.globalConfig.adminPanelUrl}/content`;
    str = `${str}/${FolderType[FolderType.imagesByFolder]}/${this.globalConfig.customerCode}/${this.globalConfig.domainID}`;
    str = `${str}/${ContentType[cnt]}/${w}/${h}/${regMoment.substring(0, 7).replace('/', '')}/${id}/${mainImgeID}/jpg`;
    return str;
   }
  public getPicturePathByRoot(cnt: ContentType, w: number, h: number, regMoment: string, fn: string, ext: string): string {
     let str = '';
     if (ext != null) {
       str = `${this.globalConfig.adminPanelUrl}/content/${FolderType[FolderType.images]}/${this.globalConfig.customerCode}`;
       str = `${str}/${this.globalConfig.domainID}/${ContentType[cnt]}/${w}/${h}/${regMoment.substring(0, 7).replace('/', '')}`;
       str = `${str}/${fn}/${ext}`;
     } else {
      str = `${this.globalConfig.adminPanelUrl}/content/${FolderType[FolderType.images]}/${this.globalConfig.customerCode}`;
      str = `${str}/${this.globalConfig.domainID}/${ContentType[cnt]}/${w}/${h}/${regMoment.substring(0, 7).replace('/', '')}/${fn}`;
    }
    return str;
  }
}
