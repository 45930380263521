import { Component, OnInit } from '@angular/core';
import { Banner } from '../../../models/banner';
import { BannerService } from '../../../services/banner.service';
import { ContentType } from '../../../models/enums/content-type.enum';
import { ContentService } from '../../../services/content.service';



@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {
  public banner: Banner;
  public cnt: ContentType = ContentType.banner;
  constructor(private bannerServices: BannerService, public contentServices: ContentService) { }
  ngOnInit() {
    this.syncBanner();
  }
  public syncBanner() {
    this.bannerServices.getList( 'top', '1').subscribe( a => {
      if ( a != null && a.length > 0) {
        this.banner = a[0];
    }
    });
  }

}
