import { Injectable } from '@angular/core';
import {isNullOrUndefined} from 'util';
import {catchError, map} from 'rxjs/operators';
import {User} from '../models/user';
import {Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {IgcoopService} from './igcoop.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private static curUser: User;
  constructor(private tknServices: IgcoopService,
              private router: Router) {

  }
  login(userName: string, password: string): Observable<Boolean> {
    if (!this.isLoggedIn()) {
      return this.tknServices.Login(userName, password).pipe(
        map((tkn: any) => {
          if (!isNullOrUndefined(tkn)) {
            const expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + 1000);
            // this.cookieService.set('smu', 'tkn.token');
            localStorage.setItem('smu', tkn.token);
            return true;
          }
          return false;
        }), catchError((err) => {
          return of(false);
        }));
    } else {
      return of(true);
    }
  }

  logout(withNavigate: boolean = true) {
    // this.cookieService.delete('smu', '/');
    localStorage.removeItem('smu');
    AuthService.curUser = null;
    if (withNavigate) {
      this.router.navigate(['/login']);
    }
  }

  getCurrentToken(): string {
    // return this.cookieService.get('smu');
    return localStorage.getItem('smu');
  }

  isLoggedIn(): boolean {
    if (!isNullOrUndefined(AuthService.curUser)) {
      return true;
    }
    const uo = localStorage.getItem('smu');
    // const uo = this.cookieService.get('smu');
    return !isNullOrUndefined(uo) && uo !== '';
  }

  getLoggedIn(): Observable<User> {
    if (!isNullOrUndefined(AuthService.curUser)) {
      return of(AuthService.curUser);
    }
    // const uo = this.cookieService.get('smu');
    const uo = localStorage.getItem('smu');

    if (!isNullOrUndefined(uo) && uo !== '') {
      return this.tknServices.GetLoggedUser().pipe(map(u => {
        AuthService.curUser = u;
        return u;
      }), catchError(error => {
        this.logout(false);
        return of(null);
      }));
    } else {
      AuthService.curUser = null;
      return of(null);
    }
  }
}
