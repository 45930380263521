import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {PageContent} from '../models/page-content';

@Injectable({
  providedIn: 'root'
})
export class PageContentService extends  BaseService<PageContent> {

  constructor(httpClient: HttpClient) {
    super( httpClient );
    this.controllerName = 'pagecontents';
  }
}
