import { Component, OnInit } from '@angular/core';
import {IPage} from './i-page';

@Component({
  selector: 'app-second-page-layout',
  templateUrl: './second-page-layout.component.html',
  styleUrls: ['./second-page-layout.component.css']
})
export class SecondPageLayoutComponent implements OnInit {
  public curPage: IPage;
  constructor() { }

  ngOnInit() {
  }
  onRouterOutletActivate(event: IPage) {
    this.curPage = event;
  }
}
