import { Component, OnInit, Input } from '@angular/core';
import { PageContent } from '../../../../models/page-content';

@Component({
  selector: 'app-apportunity-box',
  templateUrl: './apportunity-box.component.html',
  styleUrls: ['./apportunity-box.component.css']
})
export class ApportunityBoxComponent implements OnInit {
  @Input() apportunityInfo: PageContent;
  @Input() number: number;
  constructor() { }

  ngOnInit() {
  }

}
