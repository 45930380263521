import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {Post} from '../models/post';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PostService extends BaseService<Post> {
  constructor(httpClient: HttpClient) {
    super( httpClient );
    this.controllerName = 'posts';
  }
}
