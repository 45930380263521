import {Component, Input, OnInit} from '@angular/core';
import { Post } from '../../../../models/post';
import { ContentService } from '../../../../services/content.service';
import { ContentType } from '../../../../models/enums/content-type.enum';

@Component({
  selector: 'app-event-box',
  templateUrl: './event-box.component.html',
  styleUrls: ['./event-box.component.css']
})
export class EventBoxComponent implements OnInit {
  @Input() postInfo: Post;
  public cnt: ContentType = ContentType.post;
  constructor(public contentServices: ContentService) { }
  ngOnInit() {
  }

}
