import { Injectable } from '@angular/core';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {GlobalConfigService} from './global-config.service';
import {User} from '../models/user';
import {ResultObject} from '../models/result-object';
import {HouseHold} from '../models/house-hold';

@Injectable({
  providedIn: 'root'
})
export class IgcoopService {
  constructor(private httpClient: HttpClient,
              private globalConfig: GlobalConfigService) { }
  public setHousehold(model: any): Observable<any> {
    const url = `${this.globalConfig.igcoopUrl}/api/Households/set`;
    return this.httpClient.post<any>(url, model);
  }
  public getHouseholdByID(id: number): Observable<any> {
    const url = `${this.globalConfig.igcoopUrl}/api/Households/get/${id}`;
    return this.httpClient.get<any>(url);
  }
  public getHouseholdByCode(code: string): Observable<any> {
    const url = `${this.globalConfig.igcoopUrl}/api/Households/get/${code}`;
    return this.httpClient.get<any>(url);
  }
  public getHouseholdList(): Observable<ResultObject<Array<HouseHold>>> {
    const url = `${this.globalConfig.igcoopUrl}/api/Households/list`;
    return this.httpClient.post<ResultObject<Array<HouseHold>>>(url, {});
  }

  public GetLoggedUser(): Observable<User> {
    const url = `${this.globalConfig.igcoopUrl}/api/token/LoggedUser`;
    return this.httpClient.get<User>(url);
  }

  public Login(userName: string, password: string): Observable<any> {
    const url = `${this.globalConfig.igcoopUrl}/api/token`;
    const ob = {userName, password};
    return this.httpClient.post<any>(url, ob);
  }
}
