import { Component, OnInit } from '@angular/core';
import {IPage} from '../second-page-layout/i-page';
import {Service} from '../../models/service';
import {ActivatedRoute, Params} from '@angular/router';
import {ServiceService} from '../../services/service.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit, IPage {
  id: number;
  public title ;
  public abstract;
  public backImageUrl;
  public content;
  service: Service;
  constructor(private activatedRoute: ActivatedRoute, private serviceService: ServiceService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.syncService();
    });
  }
private  syncService() {
  this.serviceService.getSingle('', this.id.toString()).subscribe(p => {
    this.service = p;
    this.title = p.title;
    this.abstract = p.summary;
    this.content = p.content;
    this.backImageUrl = 'assets/img/header/sen1.jpg';
  });
}
}
