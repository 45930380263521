import { Component, OnInit } from '@angular/core';
import {PageContentService} from '../../../services/page-content.service';
import {PageContent} from '../../../models/page-content';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  public  pageContent: PageContent;
  constructor(private pageContentsServices: PageContentService) { }

  ngOnInit() {
    this.syncPageContent();
  }
  public syncPageContent() {
    this.pageContentsServices.getSingle( '', '1009').subscribe( a => {
      this.pageContent = a;
    });
  }

}
