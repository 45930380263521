import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {GlobalConfigService} from './global-config.service';


@Injectable()
export abstract class BaseService<T> {
  protected controllerName: string;
  private globalConfig: GlobalConfigService;
  constructor(private http: HttpClient) {
    this.globalConfig = new GlobalConfigService();
    // this.apiUrl = 'http://79.175.133.146:8055';
  }
  public getList(actionName: string = '', urlParams: string = ''): Observable<Array<T>> {
    // const urlStr = this.makeUrl('', top.toString());
    const urlStr = this.makeApiUrl(actionName, urlParams);
    return this.http.get<Array<T>>(urlStr);
  }
  public getSingle(actionName: string, urlParams: string): Observable<T> {
    const urlStr = this.makeApiUrl(actionName, urlParams);
    return this.http.get<T>(urlStr);
  }
  public makeApiUrl(actionName: string = '', urlparams: string = ''): string {
    let url = `${this.globalConfig.apiUrl}/api/${this.controllerName}`;
    if (actionName !== '') {
      url = `${url}/${actionName}`;
    }
    if (urlparams !== '') {
      url = `${url}/${urlparams}`;
    }
    return url;
  }
  // public getPictureLink(cnttType: string, ym: string, fn: string, ext: string, w: number, h: number): string
  // {
  //   firstPart: string;
  //   this.first = cnttType;
  //   if (ext != null) {
  //     fn = String.Format("{0}/{1}", fn, ext);
  //   }
  //   str: string = String.Format("~/content/images/{0}/{1}/{2}/{3}/{4}",
  //     firstPart, w, h, ym, fn);
  //   return str;
  // }

}
