import { Component, OnInit } from '@angular/core';
import {PageContentService} from '../../../services/page-content.service';
import {PageContent} from '../../../models/page-content';

@Component({
  selector: 'app-coporate-detail',
  templateUrl: './coporate-detail.component.html',
  styleUrls: ['./coporate-detail.component.css']
})
export class CoporateDetailComponent implements OnInit {
  public  pageContent: PageContent;
  constructor(private pageContentsServices: PageContentService) { }

  ngOnInit() {
    this.syncPageContent();
  }
  public syncPageContent() {
    this.pageContentsServices.getSingle( '', '1013').subscribe( a => {
      this.pageContent = a;
    });
  }

}
