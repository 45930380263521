import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BaseService} from './base.service';
import {Service} from '../models/service';

@Injectable()
export class ServiceService extends BaseService<Service> {
  constructor(httpClient: HttpClient) {
    super( httpClient );
    this.controllerName = 'services';
  }
  public makeMainPageSubSetsExtraInfoes(services: Service[]): Array<Service> {
    if ( services != null && services.length > 0) {
      services[0].extraClassName = 'ourBoxBlk_big';
      services[0].width = 487;
      services[0].height = 227;
      services[1].extraClassName = 'trustBlk';
      services[1].hoverClassName = 'layerTrust';
      services[1].width = 243;
      services[1].height = 227;
      services[2].extraClassName = 'commitBlk';
      services[2].hoverClassName = 'layerCommit';
      services[2].width = 243;
      services[2].height = 227;
      services[3].extraClassName = 'passionBlk';
      services[3].hoverClassName = 'layerPassion';
      services[3].width = 243;
      services[3].height = 150;
      services[4].extraClassName = 'resultBlk';
      services[4].width = 243;
      services[4].height = 150;
      services[5].extraClassName = 'integrationBlk';
      services[5].hoverClassName = 'layerTrust';
      services[5].width = 243;
      services[5].height = 150;
      services[6].extraClassName = 'dedicationBlk';
      services[6].hoverClassName = 'layerCommit';
      services[6].width = 243;
      services[6].height = 150;
      services[7].extraClassName = 'ourBoxBlk_big enterprenBlk';
      services[7].width = 487;
      services[7].height = 150;
    }
    return services;
  }
}
