import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import {AppRoutingModule} from './app-routing.module';
import { BannerComponent } from './components/home/banner/banner.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';
import { PageContentService } from './services/page-content.service';
import { PostService } from './services/post.service';
import { ServiceService } from './services/service.service';
import { ContentService } from './services/content.service';
import { BannerService } from './services/banner.service';
import { GlobalConfigService } from './services/global-config.service';
import { FeaturesComponent } from './components/home/features/features.component';
import { FeatureBox2Component } from './components/home/features/feature-box2/feature-box2.component';
import { AboutUsComponent } from './components/home/about-us/about-us.component';
import { PrioritiesComponent } from './components/home/priorities/priorities.component';
import { PriorityBoxComponent } from './components/home/priorities/priority-box/priority-box.component';
import { ActivitiesComponent } from './components/home/activities/activities.component';
import { ActivityBoxComponent } from './components/home/activities/activity-box/activity-box.component';
import { EventsComponent } from './components/home/events/events.component';
import { EventBoxComponent } from './components/home/events/event-box/event-box.component';
import { CoporateDetailComponent } from './components/home/coporate-detail/coporate-detail.component';
import { ApportunitiesComponent } from './components/home/apportunities/apportunities.component';
import { ApportunityBoxComponent } from './components/home/apportunities/apportunity-box/apportunity-box.component';
import { SecondPageLayoutComponent } from './components/second-page-layout/second-page-layout.component';
import { TopSectionComponent } from './components/second-page-layout/top-section/top-section.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PostsComponent } from './components/posts/posts.component';
import { SafeHtmlPipe } from './services/pipes/safe-html.pipe';
import { PageContentComponent } from './components/page-content/page-content.component';
import { ServicesComponent } from './components/services/services.component';
import {SummarizePipe} from './services/pipes/summarize.pipe';
import { HouseHoldsComponent } from './components/house-holds/house-holds.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { HouseHoldListComponent } from './components/house-hold-list/house-hold-list.component';




@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    BannerComponent,
    FeaturesComponent,
    FeatureBox2Component,
    AboutUsComponent,
    PrioritiesComponent,
    PriorityBoxComponent,
    ActivitiesComponent,
    ActivityBoxComponent,
    EventsComponent,
    EventBoxComponent,
    CoporateDetailComponent,
    ApportunitiesComponent,
    ApportunityBoxComponent,
    SecondPageLayoutComponent,
    TopSectionComponent,
    PageNotFoundComponent,
    PostsComponent,
    SafeHtmlPipe,
    PageContentComponent,
    ServicesComponent,
    SummarizePipe,
    HouseHoldsComponent,
    LoginComponent,
    HouseHoldListComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule
  ],
  exports: [
    SummarizePipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    HttpClient,
    PageContentService,
    PostService,
    ServiceService,
    ContentService,
    BannerService,
    GlobalConfigService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
