import { Component, OnInit } from '@angular/core';
import {Service} from '../../../models/service';
import {ServiceService} from '../../../services/service.service';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.css']
})
export class ActivitiesComponent implements OnInit {
  public services: Service[];
  constructor(private serviceServices: ServiceService) { }

  ngOnInit() {
    this.syncServices();
  }
  public syncServices() {
    this.serviceServices.getList('top', '4').subscribe( a => {
      this.services = a;
    });
  }


}
