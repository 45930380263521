import {Component, Input, OnInit} from '@angular/core';
import {PageContentService} from '../../../../services/page-content.service';
import {PageContent} from '../../../../models/page-content';

@Component({
  selector: 'app-priority-box',
  templateUrl: './priority-box.component.html',
  styleUrls: ['./priority-box.component.css']
})
export class PriorityBoxComponent implements OnInit {
  @Input() id: number;
  // @Input() iconName: string;
  public pageContent: PageContent;
  constructor(private pageContentsServices: PageContentService) { }
  ngOnInit() {
    this.syncPageContent();
  }
  public syncPageContent() {
    this.pageContentsServices.getSingle( '', this.id.toString()).subscribe( a => {
      this.pageContent = a;
    });
  }
}
