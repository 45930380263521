import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Banner} from '../models/banner';
import {BaseService} from './base.service';

@Injectable()
export class BannerService extends BaseService<Banner> {
  constructor(httpClient: HttpClient) {
    super( httpClient );
    this.controllerName = 'banners';
  }
  public makeMainPageBannersExtraInfoes(banners: Banner[]): Array<Banner> {
    if ( banners != null && banners.length > 0) {
      for (let i = 0; i < banners.length; i += 6) {
        banners[i].extraClassName = 'bnrLarg_thermal';
        // banners[i].width = 343;
        // banners[i].height = 288;
        banners[i + 1].extraClassName = 'bnrLarg_investor';
        // banners[i + 1].width = 343;
        // banners[i + 1].height = 213;
        banners[i + 2].extraClassName = 'bnrLarg_infrastructure';
        // banners[i + 2].width = 530;
        // banners[i + 2].height = 214;
        banners[i + 3].extraClassName = 'bnrLarg_port';
        // banners[i + 3].width = 385;
        // banners[i + 3].height = 215;
        banners[i + 4].extraClassName = 'bnrLarg_oil';
        // banners[i + 4].width = 287;
        // banners[i + 4].height = 287;
        banners[i + 5].extraClassName = 'bnrLarg_coal';
        // banners[i + 5].width = 627;
        // banners[i + 5].height = 289;
      }
    }
    return banners;
  }
}

