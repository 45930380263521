import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-priorities',
  templateUrl: './priorities.component.html',
  styleUrls: ['./priorities.component.css']
})
export class PrioritiesComponent implements OnInit {
  constructor() { }
  ngOnInit() {
  }
}
