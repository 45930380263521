import {Component, Input, OnInit} from '@angular/core';
import {Service} from '../../../../models/service';
import {ContentService} from '../../../../services/content.service';
import {ContentType} from '../../../../models/enums/content-type.enum';

@Component({
  selector: 'app-activity-box',
  templateUrl: './activity-box.component.html',
  styleUrls: ['./activity-box.component.css']
})
export class ActivityBoxComponent implements OnInit {
  @Input() serviceInfo: Service;
  public cnt: ContentType = ContentType.service;
  constructor(public contentServices: ContentService) { }

  ngOnInit() {
  }

}
