import { Component, OnInit } from '@angular/core';
import {IPage} from '../second-page-layout/i-page';
import { ActivatedRoute, Params } from '@angular/router';
import {PageContentService} from '../../services/page-content.service';
import {Post} from '../../models/post';
import {PostService} from '../../services/post.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit, IPage {
  id: number;
  public title ;
  public abstract;
  public backImageUrl;
  public content;
  page: Post;
  constructor(private activatedRoute: ActivatedRoute, private postService: PostService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.syncPost();
    });
  }
  private syncPost() {
    this.postService.getSingle('', this.id.toString()).subscribe(p => {
      this.page = p;
      this.title = p.title;
      this.abstract = p.summary;
      this.content = p.content;
      this.backImageUrl = 'assets/img/header/sen1.jpg';
    });
  }
}
