import { Component, OnInit } from '@angular/core';
import {IPage} from '../second-page-layout/i-page';
import {FormBuilder, Validators} from '@angular/forms';
import {isNullOrUndefined} from 'util';
import {HouseHoldAttachment} from '../../models/house-hold-attachment';
import {MediaService} from '../../services/media.service';
import {KeyValuePair} from '../../models/key-value-pair';
import {IgcoopService} from '../../services/igcoop.service';
import {ResultObject} from '../../models/result-object';

@Component({
  selector: 'app-house-holds',
  templateUrl: './house-holds.component.html',
  styleUrls: ['./house-holds.component.scss']
})
export class HouseHoldsComponent implements OnInit, IPage {
  public title = 'ضمانت نامه تسهیلات سرپرست خانوار';
  public abstract = '';
  public backImageUrl;
  public content = 'ثبت اطلاعات ثبت خانوار';
  submitted = false;
  saveWaiting = false;
  showTrackingCode = false;
  formGroup = this.formBuilder.group({
    firstName: ['', Validators.compose([Validators.required])],
    lastName: ['', Validators.compose([Validators.required])],
    mobile: ['', Validators.compose([Validators.required])],
    nationalCode: ['', Validators.compose([Validators.required])],
    postalCode: ['', Validators.compose([Validators.required])],
    address: [''],
  });
  isUploadLoading: boolean;
  fileChangedEvent: any = '';
  attachments: HouseHoldAttachment[] = [];
  isAlertShow: boolean;
  attachmentTypes: KeyValuePair<number, string> [];
  trackingCode: string;
  alertMessage = '';
  constructor(private formBuilder: FormBuilder,
              private mediaService: MediaService,
              private igcoopService: IgcoopService) { }

  ngOnInit() {
    this.syncAttachmentTypes();
  }
  submit() {
    this.submitted = true;
    if (this.formGroup.valid) {
      if (this.attachments.length < 1) {
        this.showAlert('danger', 'هیچ تصویری بارگزاری نشده است و باید تمام مستندات رو بارگزاری کنید.');
      } else {
        this.saveWaiting = true;
        const b = this.formGroup.value;
        b.attachments = this.attachments;
        this.igcoopService.setHousehold(b).subscribe((res: ResultObject<string>) => {
          this.saveWaiting = false;
          if (res.result) {
            this.showTrackingCode = true;
            this.trackingCode = res.data;
            this.showAlert('success', 'ثبت با موفقیت انجام شد.');
            setTimeout(() => {
            }, 2000);
          } else {
            this.showAlert('danger', `خطا در عملیات، دوباره امتحان کنید.`);
          }
        }, () => {
          this.saveWaiting = false;
          this.showAlert('danger', `خطا در عملیات، دوباره امتحان کنید.`);
        });
      }
    } else {
      this.showAlert('danger', 'لطفا تمامی فیلدهایی که به رنگ قرمز در آمده را به درستی پر کنید.');
    }
  }
  fileChangeEvent(event: any, type: number): void {
    this.fileChangedEvent = event;
    const file = event.target.files[0];
    if (!isNullOrUndefined(file)) {
      const fileName = file.name;
      const size = file.size;
      if (size > 4097152) {
        this.showAlert('success', 'حجم تصویر بارگزاری شده باید کمتر از 4 مگابایت باشد.');
      } else {
        const arr = fileName.split('.');
        if (!isNullOrUndefined(arr)) {
          const fileExe = arr[arr.length - 1];
          if (fileExe.toLowerCase() === 'jpg' || fileExe.toLowerCase() === 'jpeg' || fileExe.toLowerCase() === 'png'
            || fileExe.toLowerCase() === 'pdf') {
            let mediaType = 1;
            if (fileExe.toLowerCase() === 'pdf') {
              mediaType = 2;
            }
            this.uploadFile(file, mediaType, type);
          } else {
            this.showAlert('success', 'فرمت فایل انتخاب شده قابل قبول نمی باشد.');
          }
        }
      }
    }
  }
  uploadFile(file: File, mediaType: number, type: number)  {
    this.isUploadLoading = true;
    this.mediaService.SaveImageFile(file).subscribe(a => {
      this.isUploadLoading = false;
      this.attachments.push(<HouseHoldAttachment>{householdID: 0, url: a.fn, id: 0, mediaType: mediaType, fileType: type});
      this.showAlert('success', 'آپلود با موفقیت انجام شد.');
    }, () => {
      this.isUploadLoading = false;
    });
  }
  showAlert(type: string, message: string) {
    // this.alert = new Alert();
    // this.alert.type = type;
    this.alertMessage = message;
    this.isAlertShow = true;
    setTimeout(() => {
      this.isAlertShow = false;
    }, 5000);
  }
  get t() { return this.formGroup.controls; }
  attachmentTypeCount(type: number): number {
    if (!isNullOrUndefined(this.attachments)) {
      const lst = this.attachments.filter(at => at.fileType === type) ;
      if (!isNullOrUndefined(lst) && lst.length > 0) {
        return lst.length;
      }
    }
    return 0;
  }
  private syncAttachmentTypes() {
    this.attachmentTypes = this.GetAttachmentTypes();
  }
  public GetAttachmentTypes(): Array<KeyValuePair<number, string>> {
    return [
      {Key: 1, Value: 'مدارک هویتی (کپی برابر اصل شناسنامه و کارت ملی متقاضی)', Master: null},
      {Key: 2, Value: 'تکمیل فرم دوبرگی محاسبات اصل و سود تسهیلات توسط شعبه (مطابق پیوست شماره 1)', Master: null},
      {Key: 3, Value: 'مجوز فعالیت', Master: null},
      {Key: 4, Value: 'فرم ثنا', Master: null},
      {Key: 5, Value: 'تکمیل فرم درخواست صدور ضمانتنامه توسط متقاضی (مطابق پیوست شماره 2)', Master: null},
      {Key: 6, Value: 'فرم قرارداد صدور متقاضی (پیوست شماره 3)', Master: null},
      {Key: 7, Value: 'ارائه چک و یا سفته تا 120 درصد مبلغ ضمانتامه درخواستی به امضا متقاضی', Master: null},
    ];
  }
}
