import { Component, OnInit } from '@angular/core';
import {IPage} from '../second-page-layout/i-page';
import {PageContent} from '../../models/page-content';
import {ActivatedRoute, Params} from '@angular/router';
import {PageContentService} from '../../services/page-content.service';

@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.css']
})
export class PageContentComponent implements OnInit, IPage {
  id: number;
  public title ;
  public abstract;
  public backImageUrl;
  public content;
  page: PageContent;
  constructor(private activatedRoute: ActivatedRoute, private pageService: PageContentService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.id = params['id'];
      this.syncPage();
    });
  }
  private syncPage() {
    this.pageService.getSingle('', this.id.toString()).subscribe(p => {
      this.page = p;
      this.title = p.title;
      this.abstract = p.summary;
      this.content = p.content;
      this.backImageUrl = 'assets/img/header/sen1.jpg';
    });
  }
}
