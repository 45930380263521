import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import {SecondPageLayoutComponent} from './components/second-page-layout/second-page-layout.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {PostsComponent} from './components/posts/posts.component';
import {PageContentComponent} from './components/page-content/page-content.component';
import {ServicesComponent} from './components/services/services.component';
import {HouseHoldsComponent} from './components/house-holds/house-holds.component';
import {LoginComponent} from './components/login/login.component';
import {HouseHoldListComponent} from './components/house-hold-list/house-hold-list.component';
import {AuthGuard} from './auth.guard';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'households', component: HouseHoldsComponent },
  { path: 'household/list', canActivate: [AuthGuard], component: HouseHoldListComponent },
  { path: 'login', component: LoginComponent },
  { path: 'page', component: SecondPageLayoutComponent, children: [
      { path: 'posts/:id', component: PostsComponent },
      { path: 'contents/:id', component: PageContentComponent },
      { path: 'services/:id', component: ServicesComponent },
      { path: '', component: PageNotFoundComponent, pathMatch: 'full'},
      { path: '**', component: PageNotFoundComponent}
    ]},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
