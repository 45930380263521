import { Component, OnInit } from '@angular/core';
import {IgcoopService} from '../../services/igcoop.service';
import {HouseHold} from '../../models/house-hold';
import {HttpClient} from '@angular/common/http';
import {GlobalConfigService} from '../../services/global-config.service';

@Component({
  selector: 'app-house-hold-list',
  templateUrl: './house-hold-list.component.html',
  styleUrls: ['./house-hold-list.component.scss']
})
export class HouseHoldListComponent implements OnInit {
  houseHolds: HouseHold[];
  constructor(private igcoop: IgcoopService,
              private globalConfig: GlobalConfigService) { }

  ngOnInit() {
    this.igcoop.getHouseholdList().subscribe(res => {
      if (res.result) {
        this.houseHolds = res.data;
      }
    });
  }
  makeUrl(path: string): string {
    return `${this.globalConfig.igcoopUrl}/Uploads/${path}`;
  }
}
