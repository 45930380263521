import { Component, OnInit } from '@angular/core';
import { PageContentService } from '../../../services/page-content.service';
import { PageContent } from '../../../models/page-content';

@Component({
  selector: 'app-apportunities',
  templateUrl: './apportunities.component.html',
  styleUrls: ['./apportunities.component.css']
})
export class ApportunitiesComponent implements OnInit {
  public apportunities: PageContent[];
  constructor(private pageContentsServices: PageContentService) { }

  ngOnInit() {
    this.syncApportunities();
  }
  public syncApportunities() {
    this.pageContentsServices.getList( 'search', '4/4025').subscribe( a => {
      this.apportunities = a;
    });
  }

}

